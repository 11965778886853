import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Header from '../components/header'
import Services from '../components/services'
import Footer from '../components/footer'
import SEO from '../components/seo'
import '../styles/home.scss'
import '../styles/layout.scss'
import '../styles/global.scss'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query HomeMenuQuery {
        wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
          items {
            title
            object_slug
            url
            type_label
            wordpress_children {
              title
              object_slug
            }
          }
        }
        wordpressPage(slug: { eq: "home" }) {
          acf {
            title
            subtitle
            intro_title
            intro
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            seo_page_title
            seo_page_meta_description
            seo_page_keywords
          }
        }
        wordpressAcfOptions(wordpress_id: { eq: "acf" }) {
          options {
            contact_details {
              telephone_number
              email_address
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          menu={data.wordpressWpApiMenusMenusItems.items}
          contact={data.wordpressAcfOptions.options.contact_details}
        />
        <SEO
          title={
            data.wordpressPage.acf.seo_page_title !== null
              ? data.wordpressPage.acf.seo_page_title
              : data.wordpressPage.title
          }
          description={
            data.wordpressPage.acf.seo_page_meta_description !== null &&
            data.wordpressPage.acf.seo_page_meta_description
          }
          keywords={
            data.wordpressPage.acf.seo_page_keywords !== null &&
            data.wordpressPage.acf.seo_page_keywords
          }
        />
        <section className="banner">
          <Img
            alt="Header Background Image"
            fluid={
              data.wordpressPage.acf.hero_image.localFile.childImageSharp.fluid
            }
          />
          <div className="wrap">
            <h1>{data.wordpressPage.acf.title}</h1>
            <p className="subtitle">{data.wordpressPage.acf.subtitle}</p>
          </div>
        </section>
        <main className="wrap home_main">
          <h2 className="intro_title">{data.wordpressPage.acf.intro_title}</h2>
          <p
            className="intro"
            dangerouslySetInnerHTML={{
              __html: data.wordpressPage.acf.intro,
            }}
          />
          <Services />
        </main>
        <Footer />
      </>
    )}
  />
)

export default IndexPage
